import React, { useState } from 'react';

const ReservationForm = () => {

    const [inputs, setInputs] = useState();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const resp = fetch('api/reserveSpot', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            body: JSON.stringify(inputs)
        });
        resp
            .then(response => response.json())
            .then(data => {
                console.log(data);
            }).catch(() => alert('Error submitting form'));
    };

    return (
        <form className="ui form" onSubmit={handleSubmit}>
            <div className="field">
                <label>Individual / Organization Name</label>
                <div className="field">
                    <input required type="text" name="name" placeholder="Individual / Organization Name" onChange={handleChange}></input>
                    <input type="hidden" name="spot" />
                </div>
            </div>
            <div className="field">
                <label>Email</label>
                <div className="field">
                    <input required type="email" name="email" placeholder="example@example.com" onChange={handleChange}></input>
                </div>
            </div>
            <div className="field">
                <label>Phone Number (xxx-xxx-xxxx)</label>
                <div className="field">
                    <input required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" type="text" name="phoneNumber" placeholder="xxx-xxx-xxxx" onChange={handleChange}></input>
                </div>
            </div>
            <div className="field">
                <label>Description of items being sold</label>
                <div className="field">
                    <input required type="text" name="itemsToSell" placeholder="Description of items" onChange={handleChange}></input>
                </div>
            </div>
            <div className="field">
                <label>Will you have a generator?</label>
                <div className="field" style={{ display: 'inline-block' }}>
                    <label htmlFor="generator-yes" style={{ display: 'inline', paddingRight: '.3vw' }}>Yes</label>
                    <input required type="radio" id="generator-yes" name="generator" value="yes" style={{ display: 'inline' }} onChange={handleChange} />
                    <label htmlFor="generator-no" style={{ display: 'inline', paddingRight: '.3vw', paddingLeft: '.3vw' }}>No</label>
                    <input required type="radio" id="generator-no" name="generator" value="no" style={{ display: 'inline' }} onChange={handleChange} />
                </div>
            </div>
            <div className="field">
                <label>What will you work from?</label>
                <div className="field" style={{ display: 'inline-block' }}>
                    <label htmlFor="trailer" style={{ display: 'inline', paddingRight: '.3vw' }}>Concession / Merchandise Trailer</label>
                    <input required type="radio" id="trailer" name="workSite" value="trailer" style={{ display: 'inline' }} onChange={handleChange} />
                    <label htmlFor="tents-tables" style={{ display: 'inline', paddingRight: '.3vw', paddingLeft: '.3vw' }}>Tents / Tables</label>
                    <input required type="radio" id="tents-tables" name="workSite" value="tents-tables" style={{ display: 'inline' }} onChange={handleChange} />
                </div>
            </div>
            <button className="ui button" type="submit">Submit</button>
        </form>
    );
};

export default ReservationForm;