import './App.css';
import ReservationForm from './components/TestForm';

function App() {
  return (
    <ReservationForm>
    </ReservationForm>
  );
}

export default App;
